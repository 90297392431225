<template >
  
  <div>
    <h1>Bienvenido coordinador</h1>
    <div><!--PARTE DONDE SE CONFIGURA LA NAVBAR-->
      <b-navbar toggleable="lg" type="dark" variant="succes">

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav><!--Todo lo que se encuentre dentro se desplegará o colapsará a traves del botón-->
          <b-navbar-nav>
            <b-nav-item><router-link to="/dcuestionarios2">Definir Cuestionario</router-link></b-nav-item>
            <b-nav-item><router-link to="/dencuestas2">Definir Encuesta</router-link></b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>

    

  </div>
</template>




<script>
  import axios from 'axios'
  export default {

    data() {
      return {
        //variables
        text:'ejemplo',
        usuario:'',
        pass:'',
      }
    },



    
    ///variables


    methods: { 
      miMetodo() {
        alert('hola');
        axios({
          method: 'get',
          url: 'https://recaudacion.gobinet.mx/servicios',
          params:{"codeClient":"calimaya"},
          data: {
              "idToken": '',
              "usuario":this.usuario,
              "pass":this.pass
          }
        })
        .then(res => {
            this.text = res.data;
        }).catch(err => {
            //errores
            alert(err);
        });
        },
      }
    }
  
</script>



<style>
.backmycolor{
  background-color: rgb(255, 0, 0);
}
</style>